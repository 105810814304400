.switcher {
  .businessInner {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    gap: 0.5rem;
    .businessImage {
      display: grid;
      place-items: center;
      width: 3rem;
      height: 3rem;
      color: #444444;
      background-color: gainsboro;
      border-radius: 50%;
    }
    .businessName {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 280px;
      font-size: medium;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}
